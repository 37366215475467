<template>
    <div class="item-cards">
      <div class="item-card" :id="itemIdentifier(item, index)"
        ref="itemCards" data-has-icon="false"
        v-for="(item, index) of fields" :key="index"
      >
        <div class="inner clickable-card" @click="triggerAction(index)">
          <b-img class="icon img-thumbnail rounded-circle"/>
          <component :is="`icon-${itemType}`" class="default-icon icon" />
          <span>
            {{iconLabel(item)}}
          </span>
        </div>
      </div>
    </div>
</template>

<script>
import IconService from '@/modules/config/services/icon.service'
import ModelService from '@/modules/insight/services/model.service'

import IconLink from '@/assets/la-link.svg'
import IconEntity from '@/assets/entity.svg'

export default {
  name: 'icon',
  components: {
    IconLink, IconEntity
  },
  data: () => ({
    chosenModel: null,
    error: false,
    iconCache: {}
  }),
  props: {
    fields: {
      default: () => ([])
    },
    itemType: {
      type: String
    },
    usage: {
      type: String,
      default: 'sourceDBMapping'
    },
    selectedFormModel: {
      default: null
    }
  },
  mounted () {
    this.setIcons()
    this.setSelectedFormIcon(this.selectedFormModel)
  },
  updated () {
    this.setIcons()
    this.setSelectedFormIcon(this.chosenModel)
  },
  computed: {
    isForSourceDBMapping () {
      return this.usage === 'sourceDBMapping'
    }
  },
  methods: {
    triggerAction (model) {
      this.setSelectedFormIcon(model)
      this.$emit('triggerAction', model)
    },
    setIcons () {
      if (this.$refs.itemCards && this.isForSourceDBMapping) {
        this.fields.forEach((field) => {
          const itemCard = this.$refs.itemCards.find(item => parseInt(item.id) === field.id)
          if (itemCard) {
            if (typeof field.configuration.image === 'string') {
              this.setIcon(field.id, itemCard)
              itemCard.dataset.hasIcon = true
            } else if (field.configuration.imagePreview) {
              itemCard.querySelector('.icon.img-thumbnail').src = field.configuration.imagePreview
              itemCard.dataset.hasIcon = true
            } else {
              itemCard.dataset.hasIcon = false
            }
          }
        })
      } else if (this.$refs.itemCards && !this.isForSourceDBMapping) {
        const fields = this.iconCache[this.itemType] || this.fields
        const useCache = !!this.iconCache[this.itemType]
        this.iconCache[this.itemType] = this.iconCache[this.itemType] || this.fields
        for (const model in fields) {
          const itemCard = this.$refs.itemCards.find(item => item.id === model)
          if (itemCard) { this.setIcon(model, itemCard, useCache) }
        }
      }
    },
    async setIcon (identifier, itemCard, useCache) {
      try {
        let response
        if (this.isForSourceDBMapping) {
          response = await IconService.getImage(identifier)
        } else {
          response = useCache ? this.iconCache[this.itemType][identifier].icon : await ModelService.getIconImage(identifier)
        }
        if (response.status === 200) {
          itemCard.querySelector('.icon.img-thumbnail').src = window.URL.createObjectURL(new Blob([response.data]))
          if (!this.isForSourceDBMapping) {
            itemCard.dataset.hasIcon = true
            if (!useCache) {
              this.iconCache[this.itemType][identifier].icon = response
            }
          }
        }
      } catch (err) {
        this.error = err
      }
    },
    setSelectedFormIcon (model) {
      if (this.isForSourceDBMapping) {
        return false
      } else {
        const boxShadowStyle = this.$FORM('STYLE').ICON_CARD_BOXSHADOW
        const chosenCard = this.$refs.itemCards.find(item => item.id === model)
        const previousCard = this.$refs.itemCards.find(item => item.id === this.chosenModel)
        const sameAsPrevious = chosenCard === previousCard
        if (chosenCard && !sameAsPrevious) {
          chosenCard.querySelector('.inner.clickable-card').style.boxShadow = boxShadowStyle
        }
        if (previousCard) {
          previousCard.querySelector('.inner.clickable-card').style.boxShadow = sameAsPrevious ? boxShadowStyle : null
        }
        this.chosenModel = model
      }
    },
    itemIdentifier (item, index) {
      return this.isForSourceDBMapping ? item.id : index
    },
    iconLabel (item) {
      return this.isForSourceDBMapping ? item.name : item.label
    }
  }
}
</script>

<style lang="scss" scoped>
.item-card[data-has-icon='false']{
  .inner{
    .icon {
      display: none;
    }
    .default-icon{
      display: block;
    }
  }
}

.item-card[data-has-icon='true']{
  .inner{
    .icon {
      display: block;
    }
    .default-icon{
      display: none;
    }
  }
}

</style>
