import ApiService from '@/modules/auth/services/api.service'

class IconError extends Error {
  constructor (errorCode, message) {
    super(message)
    this.name = this.constructor.name
    this.message = message
    this.errorCode = errorCode
  }
}

const IconService = {
  getImage: async function (model) {
    try {
      const response = await ApiService.customRequest({
        method: 'get',
        url: '/config/entity_link_types/get_image/?table_id=' + model,
        responseType: 'blob'
      })
      return response
    } catch (error) {
      throw new IconError(error.response.status, error.response.data)
    }
  },
  getImageByModel: async function (model) {
    try {
      const response = await ApiService.customRequest({
        method: 'get',
        url: '/config/entity_link_types/get_image/?model=' + model,
        responseType: 'blob'
      })
      return response
    } catch (error) {
      // throw new IconError(error.response.status, error.response.data)
      return false
    }
  }
}

export { IconService, IconError }
export default IconService
