<template>
  <wizard-container :step="4">
    <b-card v-data-cy="'dbmapping'" class="va-card fade-in" :header="header">
      <b-modal v-data-cy="'details-modal'" ref="open-modal" size="lg" :title="modalContent.name" hide-footer>
          <b-form @dragover.prevent @drop.prevent>
            <b-form-group v-data-cy="'field-label'">
              <div class="d-flex">
                <div class="mr-2">{{ $CONFIG('WIZARD_PAGE_4').LABEL.LABEL }} </div>
                <LaInfoCircle id="popover" class="info-circle" width="18"/>
                <b-popover target="popover" triggers="hover focus">
                  <template #title>{{ $CONFIG('WIZARD_PAGE_4').TOOLTIP.LABEL_FORMATTER_TITLE }}</template>
                  <span v-encode-styled-html="$CONFIG('WIZARD_PAGE_4').TOOLTIP.LABEL_FORMATTER_BODY_INTRO"></span>
                  <span v-encode-styled-html="$CONFIG('WIZARD_PAGE_4').TOOLTIP.LABEL_FORMATTER_BODY_EXAMPLE_ONE"></span>
                  <span v-encode-styled-html="$CONFIG('WIZARD_PAGE_4').TOOLTIP.LABEL_FORMATTER_BODY_EXAMPLE_TWO"></span>
                </b-popover>
              </div>
              <b-form-input ref="labelTextRef" v-on:keyup="showLabelTextDropdown" v-model="labelText" :placeholder="$CONFIG('WIZARD_PAGE_4').PLACEHOLDER.LABEL" :state=labelTextValidation :maxlength="$CONFIG('WIZARD_PAGE_4').LABEL.MAXLENGTH"></b-form-input>
              <b-form-invalid-feedback>
                {{ $CONFIG('WIZARD_PAGE_4').ERROR.LABEL_FORMAT }}
              </b-form-invalid-feedback>
              <p class="text-right my-0"><small>{{labelTextCharactersRemaining}} {{$LOCAL('COMMON_WORD').CHARACTERS_REMAINING}}</small></p>
            </b-form-group>
            <b-dropdown v-if="labelTextDropdown" block id="dropdown-1" menu-class="w-100" class="mb-2" ref="dropdown">
              <template #button-content>
                {{ $CONFIG('WIZARD_PAGE_4').TEXT.LABEL_DROPDOWN_TEXT }}
              </template>
              <b-dropdown-item v-for="(item, index) in modalContent.labelChoices" :value="item.value" :key="index" @click="insertFieldAtCursorInLabelText(item.value)">{{ item.value }}</b-dropdown-item>
            </b-dropdown>
            <b-form-group v-data-cy="'field-icon'" v-if="window === 'entity'"
                          :label="$CONFIG('WIZARD_PAGE_4').LABEL.ICON"
                          :state="modalContent.imageState"
                          :invalid-feedback="modalContent.error">
              <b-form-file ref="image-upload" :placeholder="$CONFIG('WIZARD_PAGE_4').PLACEHOLDER.UPLOAD_IMAGE" class="shadow-sm"
                           v-model="modalContent.imageTemp" @change="createIconPreview" accept="image/*"/>
              <p v-if="modalContent.error && checkInitialIcon(fieldTypes[itemIndex].configuration.image)">
                <span @click="resetIcon(itemIndex)" class="text-primary ml-1 cursor-pointer">
                  {{ $CONFIG('WIZARD_PAGE_4').ACTION.ICON_RESTORE }}
                </span>
              </p>
            </b-form-group>
            <b-form-group v-data-cy="'field-icon-preview'" v-if="modalContent.previewTemp">
                <div class="d-flex justify-content-center">
                  <div class="d-flex flex-column">
                    <img class="img-thumbnail icon-preview rounded-circle fade-in" :src="modalContent.previewTemp" alt="Rounded circle fade in"/>
                    <span class="font-weight-bold text-center">{{ modalContent.typeName }} {{ $CONFIG('WIZARD_PAGE_4').ACTION.ICON_PREVIEW }}</span>
                    <span @click="removeIcon" class="font-weight-normal text-danger text-center btn cursor-pointer">{{ $CONFIG('WIZARD_PAGE_4').ACTION.ICON_REMOVE }}</span>
                  </div>
                </div>
            </b-form-group>
            <b-form-group v-data-cy="'field-description'" :label="$CONFIG('WIZARD_PAGE_4').LABEL.DESCRIPTION">
              <b-card class="shadow-sm">
                <span>{{ modalContent.description }}</span>
              </b-card>
            </b-form-group>
            <b-form-group v-data-cy="'field-end-types'" v-if="window === 'link'" :label="$CONFIG('WIZARD_PAGE_4').LABEL.END_TYPES">
              <b-table class="text-left" head-variant="light" table-variant="light" :bordered="true" :fixed="true" :items="endTypesTable" />
            </b-form-group>
            <div class="config-actions">
              <b-button variant="danger" @click="$refs['open-modal'].hide()">
                {{ $LOCAL('COMMON_WORD').CANCEL }}
              </b-button>
              <b-button variant="success" @click="storeChanges()">
                {{ $LOCAL('COMMON_WORD').OK }}
              </b-button>
            </div>
          </b-form>
      </b-modal>

      <div class="loader d-flex flex-row justify-content-center" >
        <b-spinner v-if="overallLoading" variant="secondary" large :label="`${$LOCAL('COMMON_WORD').LOADING}...`"></b-spinner>
      </div>

      <div v-data-cy="'list-view'" class="list window" v-show="window !== 'generate'">

        <div class="d-flex tab-container fade-in" v-if="!overallLoading">
          <b-button v-data-cy="'entity-tab-btn'" class="button-tab" @click="updateIconAndWindowType('entity')" :pressed="window === 'entity'">
            {{ entityCaption }}
          </b-button>
          <b-button v-data-cy="'link-tab-btn'" class="button-tab" @click="updateIconAndWindowType('link')" :pressed="window === 'link'">
            {{ linkCaption }}
          </b-button>
        </div>

        <b-container class="fade-in" v-if="!overallLoading">
          <icon ref="icon" :usage="usage" :fields="fieldTypes" :itemType="iconType" @triggerAction="showModal" :selectedFormModel="selectedFormModel"/>
        </b-container>

        <b-alert show v-show="error" variant="danger">
          {{ error }}
        </b-alert>

        <div class="config-actions" v-if="(window != 'generate') && (usage === 'sourceDBMapping')">
          <b-button variant="danger" :disabled="overallLoading" @click="initialise">
            <span>{{ $LOCAL('COMMON_WORD').REFRESH }}</span>
          </b-button>

          <b-button variant="success" :disabled="overallLoading" @click="generateAndSave">
            <b-spinner small v-if="overallLoading === true" :label="`${$LOCAL('COMMON_WORD').CHECKING}...`" />
            <span v-else>{{ $CONFIG('WIZARD_PAGE_4').ACTION.GENERATE_AND_SAVE }}</span>
          </b-button>
        </div>

        <!-- for Form Builder Usage -->
        <div class="config-actions mt-4" v-if="(window != 'generate') && (usage === 'formBuilder')">
          <b-button variant="danger" :disabled="overallLoading" @click="cancelChoose">
            Cancel
          </b-button>

          <b-button variant="success" :disabled="formLoading || !modelTypeLabel" @click="chooseModelType">
            Select
            <b-spinner v-if="formLoading" small />
          </b-button>
        </div>
      </div>

      <div v-data-cy="'generate-view'" class="generate window" v-show="window === 'generate'">
        <b-container>
          <b-row>
            <b-col>
              <div class="heading">
                {{ entityCaption }}
              </div>
              <div class="items">
                <div class="item" v-for="(item, index) of generateEntityTypes" :key="index">
                  <div class="status">
                    <b-spinner small v-if="item.done === null" :label="`${$LOCAL('COMMON_WORD').GENERATING}...`"></b-spinner>
                    <div class="check" v-if="item.done === true"></div>
                    <div class="cross" v-if="item.done === false"></div>
                  </div>
                  <div class="name">
                    {{ entityTypes[index].name }}
                  </div>
                </div>
              </div>
            </b-col>

            <b-col>
              <div class="heading">
                {{ linkCaption }}
              </div>
              <div class="items">
                <div class="item" v-for="(item, index) of generateLinkTypes" :key="index">
                  <div class="status">
                    <b-spinner small v-if="item.done === null" :label="`${$LOCAL('COMMON_WORD').GENERATING}...`"></b-spinner>
                    <div class="check" v-if="item.done === true"></div>
                    <div class="cross" v-if="item.done === false"></div>
                  </div>
                  <div class="name">
                    {{ linkTypes[index].name }}
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="heading">
                {{ $CONFIG('WIZARD_PAGE_4').HEADING }}
              </div>
              <div class="items">
                <div class="item" v-for="(item, index) of securityGroups" :key="index">
                  <div class="status">
                    <b-spinner small v-if="item.done === null" :label="`${$LOCAL('COMMON_WORD').SETTING}...`"></b-spinner>
                    <div class="check" v-if="item.done === true"></div>
                    <div class="cross" v-if="item.done === false"></div>
                  </div>
                  <div class="name">
                    {{ securityGroups[index].name }}
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>

        <b-alert show v-show="error" variant="danger">
          {{ error }}
        </b-alert>

        <b-alert show v-show="done" variant="success">
          {{ generationMessage }}
        </b-alert>

        <b-alert show v-show="done && Object.keys(generatingErrors).length > 0" variant="warning">
          <ul>
            <li v-for="(value, name) in generatingErrors" :key="name">
              <span> {{ name }} {{ $CONFIG('WIZARD_PAGE_4').ERROR.NOT_SAVED }} {{ value }}</span>
            </li>
          </ul>
        </b-alert>

        <div class="config-actions">
          <b-button variant="danger" @click="updateIconAndWindowType('entity')">
            <span>{{ $LOCAL('COMMON_WORD').CANCEL }}</span>
          </b-button>

          <b-button variant="warning" v-if="!showRetry" @click="runFailed">
            <span v-if="done">{{ $LOCAL('COMMON_WORD').RETRY }}</span>
            <b-spinner small v-if="!done" :label="`${$LOCAL('COMMON_WORD').GENERATING}...`"></b-spinner>
          </b-button>

          <b-button variant="success" v-if="showRetry" :disabled="disableFinish" @click="finish">
            <span v-if="done">{{ $LOCAL('COMMON_WORD').FINISH }}</span>
            <b-spinner small v-if="!done" :label="`${$LOCAL('COMMON_WORD').GENERATING}...`"></b-spinner>
          </b-button>
        </div>
      </div>
    </b-card>
  </wizard-container>
</template>

<script>
import WizardContainer from '../../components/Container'
import Icon from '../../components/Icon'
import IconService from '../../services/icon.service'
import { mapActions, mapState } from 'vuex'
import LaInfoCircle from '@/assets/la-info-circle.svg'

export default {
  name: 'SourceDBMapping',
  components: {
    WizardContainer, Icon, LaInfoCircle
  },
  props: {
    // below is for selecting entity or link in form builder
    usage: {
      default: 'sourceDBMapping'
    },
    selectedFormModel: {
      default: null
    }
  },
  data: () => ({
    labelText: '',
    labelTextDropdown: false,
    labelTextValidation: null,
    endTypesTable: [{
      end1_types: '',
      end2_types: ''
    }],
    generationMessage: '',
    window: 'entity',
    generateEntityTypes: [],
    generateLinkTypes: [],
    done: false,
    saving: false,
    totalNumberGenerated: 0,
    modalContent: {},
    itemIndex: 0,
    canFinish: false,
    isSecGroupDone: null,
    securityGroups: [{ name: 'Setting', done: null }],
    // below is for formBuilder Usage
    modelTypeLabel: null,
    formLoading: false,
    iconType: 'entity'
  }),
  computed: {
    ...mapState('config/insight', ['error', 'errorCode', 'entityTableLoading', 'linkTableLoading', 'entityTypes', 'linkTypes', 'generatingErrors']),
    ...mapState('insight', ['entities', 'links']),
    header () {
      if (this.usage === 'formBuilder') {
        return this.$FORM('TEXT').TITLE.CHOOSE_FORM_TYPE
      } else {
        return this.$CONFIG('WIZARD_PAGE_4').HEADER
      }
    },
    entityCaption () {
      const entityLength = this.usage === 'sourceDBMapping' ? this.entityTypes.length : Object.keys(this?.entities)?.length
      return `${this.$CONFIG('WIZARD_PAGE_4').TEXT.ENTITY_TYPES} (${entityLength})`
    },
    linkCaption () {
      const linkTypesLength = this.usage === 'sourceDBMapping' ? this.linkTypes.length : Object.keys(this?.links)?.length
      return `${this.$CONFIG('WIZARD_PAGE_4').TEXT.LINK_TYPES} (${linkTypesLength})`
    },
    overallLoading () {
      return this.entityTableLoading || this.linkTableLoading
    },
    isWizard () {
      return this.$route.meta.wizard !== undefined
    },
    fieldTypes () {
      if (this.usage === 'sourceDBMapping') {
        return (this.window === 'entity') ? this.entityTypes : this.linkTypes
      } else {
        return (this.window === 'entity') ? this.entities : this.links
      }
    },
    showRetry () {
      if (this.done) {
        if (!this.canFinish) {
          return false
        }
      }
      return true
    },
    disableFinish () {
      if (this.done) {
        if (this.canFinish) {
          return false
        }
      }
      return true
    },
    labelTextCharactersRemaining () {
      return this.$CONFIG('WIZARD_PAGE_4').LABEL.MAXLENGTH - this.labelText.length
    }
  },
  watch: {
    labelText (val) {
      /*
       * This function will check if labelText is valid similar to Balanced Paranthesis algorithm,
       * whereby every matching open bracket need to have a matching close bracket e.g. "{hello}" -> this is ok
       * (with exception to the last bracket e.g. "{hello} {world" -> this is ok
       * but there cannot be nesting brackets e.g. "{{" -> this is nok
       */
      const tempLabelText = val
      // Strip all other characters so that only the curly brackets remain
      // e.g. "{hello world} } bye }" will become "{}}}"
      const curlyBrackets = tempLabelText.replace(new RegExp('[^{}]+', 'g'), '')

      // Validate if curlyBrackets are matching. A valid matching curlyBrackets e.g. "{}{}{}" will have the bracket
      // at every even index being a open bracket "{" and every odd index being a closed bracket "}"
      let balancedCurlyBraces = true
      for (let i = 0; i < curlyBrackets.length; i++) {
        if ((i % 2) === 0) {
          // even index
          if (curlyBrackets[i] !== this.$LOCAL('KEYS').OPEN_BRACKET.key) {
            balancedCurlyBraces = false
            break
          }
        } else {
          // odd index
          if (curlyBrackets[i] !== this.$LOCAL('KEYS').CLOSE_BRACKET.key) {
            balancedCurlyBraces = false
            break
          }
        }
      }

      if (!balancedCurlyBraces) {
        this.labelTextValidation = false
      } else if (curlyBrackets[curlyBrackets.length - 1] === this.$LOCAL('KEYS').OPEN_BRACKET.key) {
        // For the last bracket, we do not validate if there is a matching close bracket in event user is still typing
        // e.g. {hello} {world
        this.labelTextValidation = null
      } else {
        this.labelTextValidation = true
      }
    },
    window (value) {
      if (value !== 'generate') {
        this.generationMessage = ''
        this.generateEntityTypes = []
        this.generateLinkTypes = []
        this.totalNumberGenerated = 0
        this.done = false
        this.isSecGroupDone = null
        this.securityGroups = [{ name: 'Setting', done: null }]
      }
    },
    done (bool) {
      if (bool) {
        const total = this.entityTypes.length + this.linkTypes.length
        this.generationMessage = `${this.$CONFIG('WIZARD_PAGE_4').SUCCESS.COMPLETED} ${this.totalNumberGenerated} out of ${total} ${this.$CONFIG('WIZARD_PAGE_4').SUCCESS.ENTITY_LINK_SAVED}`
        if (this.totalNumberGenerated === total && this.isSecGroupDone) {
          this.generationMessage += `${this.$CONFIG('WIZARD_PAGE_4').TEXT.SECURITY_GROUPS_SAVED}`
          this.canFinish = true
        } else {
          this.canFinish = false
        }
      }
    },
    errorCode (value) {
      if (value === '') {
        return
      }

      if (value === 999) {
        this.$bvToast.toast(this.error, {
          title: `${this.$CONFIG('COMMON').SERVER_ERROR}`,
          autoHideDelay: 5000,
          variant: 'danger',
          opacity: 1
        })
      }
    }
  },
  mounted () {
    this.initialise()
  },
  methods: {
    ...mapActions('config', [
      'restartServer'
    ]),
    ...mapActions('config/insight', [
      'fetchEntityTypes',
      'fetchLinkTypes',
      'generateOneModel',
      'deleteGeneratedModels',
      'restartInsightServer',
      'clearGeneratingErrors',
      'fetchSecurityGroups',
      'generateElasticSearchModelAndRestartInsightServer'
    ]),
    ...mapActions('insight', [
      'getEntities',
      'getLinks'
    ]),
    async initialise () {
      this.window = 'entity'
      this.iconType = 'entity'
      if (this.usage === 'sourceDBMapping') {
        await this.fetchEntityTypes()
        await this.fetchLinkTypes()
        // eslint-disable-next-line no-unused-vars
        for (const index in this.entityTypes) {
          // insert imagePreview on configuration property for image previewing purpose
          this.entityTypes[index].configuration.imagePreview = null
          // set table id into types id, need to done this because when user run a first time mapping, the table_id still not stored yet in database
          this.entityTypes[index].configuration.table_id = this.entityTypes[index].id
          // we need 'done' property to indicate whether the generation process already done or not yet
          this.entityTypes[index].done = false
        }
        let index
        for (index in this.linkTypes) {
          // set table id into types id, need to done this because when user run a first time mapping, the table_id still not stored yet in database
          this.linkTypes[index].configuration.table_id = this.linkTypes[index].id
          // we need 'done' property to indicate whether the generation process already done or not yet
          this.linkTypes[index].done = false
        }
      } else {
        await this.getEntities()
        await this.getLinks()
      }
    },
    async generateAndSave () {
      this.window = 'generate'
      for (const item of this.entityTypes) {
        this.generateEntityTypes.push({
          ...item.configuration,
          done: null
        })
      }
      for (const item of this.linkTypes) {
        this.generateLinkTypes.push({
          ...item.configuration,
          done: null
        })
      }

      await this.deleteGeneratedModels()
      this.totalNumberGenerated = 0
      for (const key in this.generateEntityTypes) {
        // if user want to remove the image that they already assign, json data type will be posted instead with Null value on image property
        if (this.generateEntityTypes[key].image) {
          const formData = new FormData()
          // check whether user want upload new files. if not a File type, it will recognised as updating the other value
          // without removing current image that already up on BE
          if (this.generateEntityTypes[key].image instanceof File) {
            formData.set('image', this.generateEntityTypes[key].image)
          }
          formData.set('label', this.generateEntityTypes[key].label)
          formData.set('table_id', this.generateEntityTypes[key].table_id)
          this.generateEntityTypes[key].done = await this.generateOneModel({
            data: formData, name: this.entityTypes[key].name
          })
        } else {
          this.generateEntityTypes[key].done = await this.generateOneModel({
            data: this.generateEntityTypes[key], name: this.entityTypes[key].name
          })
        }

        if (this.generateEntityTypes[key].done) {
          this.totalNumberGenerated += 1
        }
      }
      for (const key in this.generateLinkTypes) {
        this.generateLinkTypes[key].done = await this.generateOneModel({
          data: this.generateLinkTypes[key], name: this.linkTypes[key].name
        })
        if (this.generateLinkTypes[key].done) {
          this.totalNumberGenerated += 1
        }
      }

      await this.loadSecurityGroups()

      this.done = true
    },
    async runFailed () {
      this.window = 'generate'
      this.done = false
      this.clearGeneratingErrors()
      for (const key in this.generateEntityTypes) {
        if (this.generateEntityTypes[key].done) {
          continue
        }
        // if user want to remove the image that they already assign, json data type will be posted instead with Null value on image property
        if (this.generateEntityTypes[key].image) {
          const formData = new FormData()
          // check whether user want upload new files. if not a File type, it will recognised as updating the other value
          // without removing current image that already up on BE
          if (this.generateEntityTypes[key].image instanceof File) {
            formData.set('image', this.generateEntityTypes[key].image)
          }
          formData.set('label', this.generateEntityTypes[key].label)
          formData.set('table_id', this.generateEntityTypes[key].table_id)
          this.generateEntityTypes[key].done = await this.generateOneModel({
            data: formData, name: this.entityTypes[key].name
          })
        } else {
          this.generateEntityTypes[key].done = await this.generateOneModel({
            data: this.generateEntityTypes[key], name: this.entityTypes[key].name
          })
        }

        if (this.generateEntityTypes[key].done) {
          this.totalNumberGenerated += 1
        }
      }
      for (const key in this.generateLinkTypes) {
        if (this.generateLinkTypes[key].done) {
          continue
        }
        this.generateLinkTypes[key].done = await this.generateOneModel({
          data: this.generateLinkTypes[key], name: this.linkTypes[key].name
        })
        if (this.generateLinkTypes[key].done) {
          this.totalNumberGenerated += 1
        }
      }

      if (!this.isSecGroupDone) {
        await this.loadSecurityGroups()
      }

      this.done = true
    },
    async finish () {
      await this.restartInsightServer()

      this.done = false

      if (this.errorCode === 0) {
        let navigateTo = 'Settings'
        if (this.isWizard === true) {
          navigateTo = 'Wizard Step 5'
        }
        this.$root.$bvToast.toast(`${this.totalNumberGenerated} ${this.$CONFIG('WIZARD_PAGE_4').SUCCESS.ENTITY_LINK_SUCCESSFULLY_SAVED}`, {
          title: this.$CONFIG('WIZARD_PAGE_4').SUCCESS.SUCCESS,
          autoHideDelay: 5000,
          appendToast: true,
          variant: 'success'
        })

        // First check the server online status without emitting server restarted
        await this.restartServer(false)
        await this.generateElasticSearchModel(navigateTo)
      }
    },
    /**
     * @public
     * @async
     * This function calls the generate elastic model API. Upon calling the API, the server will be restarted and
     * another API will be triggered to check the server online status. Once the server is up, the user is navigated
     * based on the navigateTo param
     * @param navigateTo The router link to navigate to
     **/
    async generateElasticSearchModel (navigateTo) {
      // Trigger the API to generate elastic search model and restart server
      await this.generateElasticSearchModelAndRestartInsightServer()

      // Check the server online status and emit server restarted
      await this.restartServer()

      if (this.errorCode === 0) {
        this.$router.push({
          name: navigateTo
        })
      }
    },
    async showModal (value) {
      if (this.usage === 'sourceDBMapping') {
        this.itemIndex = value
        this.modalContent = {
          name: this.fieldTypes[value].name,
          labelTemp: this.fieldTypes[value].configuration.label,
          labelChoices: this.fieldTypes[value].label_choices,
          imageTemp: this.fieldTypes[value].configuration.image,
          previewTemp: this.fieldTypes[value].configuration.imagePreview,
          description: this.fieldTypes[value].description,
          error: null
        }

        // Set modal label text with modalContent.labelTemp
        this.labelText = this.modalContent.labelTemp ? this.modalContent.labelTemp : ''

        // Hide dropdown
        this.labelTextDropdown = false

        // if there is image that was assigned before
        if (typeof this.modalContent.imageTemp === 'string') {
          this.setIconPreview(this.fieldTypes[value].id)
        }

        // set the data that needed to be showed into End Types Table
        if (this.window === 'link') {
          this.endTypesTable[0].end1_types = this.fieldTypes[value].end_types.end1_types.join(', ')
          this.endTypesTable[0].end2_types = this.fieldTypes[value].end_types.end2_types.join(', ')
        }
        this.$refs['open-modal'].show()
      } else {
        this.modelTypeLabel = value
      }
    },
    storeChanges () {
      // store the changes when user input new values in pop-up modal
      // fieldTypes are computed property, if this.window === 'entity', it will stored in entityType
      this.fieldTypes[this.itemIndex].configuration.label = this.labelText
      this.fieldTypes[this.itemIndex].configuration.image = this.modalContent.imageTemp
      this.fieldTypes[this.itemIndex].configuration.imagePreview = this.modalContent.previewTemp
      this.$refs['open-modal'].hide()
      this.$refs.icon.setIcons()
    },
    createIconPreview (event) {
      let returnValue = true
      const file = event.target.files[0] ? event.target.files[0] : event.dataTransfer.files[0]
      if (!file) {
        returnValue = false
      } else {
        if (file.type.match('image.*')) {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = this.handlerForFileUpload
        } else {
          this.modalContent.imageTemp = null
          this.modalContent.previewTemp = null
          this.$refs['image-upload'].reset()
          this.modalContent.error = this.$CONFIG('WIZARD_PAGE_4').ERROR.UPLOAD_ERROR
          this.modalContent.imageState = false
        }
      }
      return returnValue
    },
    async setIconPreview (itemId) {
      const response = await IconService.getImage(itemId)
      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        this.modalContent.previewTemp = url
      } else {
        this.modalContent.previewTemp = null
      }
    },
    removeIcon () {
      this.modalContent.imageTemp = null
      this.modalContent.previewTemp = null
      this.modalContent.imageState = null
    },
    resetIcon (itemIndex) {
      this.setIconPreview(this.fieldTypes[itemIndex].id)
      this.modalContent.imageTemp = this.fieldTypes[itemIndex].configuration.image
      this.modalContent.previewTemp = this.fieldTypes[itemIndex].configuration.imagePreview
      this.modalContent.error = ''
      this.modalContent.imageState = null
    },
    handlerForFileUpload (e) {
      this.modalContent.previewTemp = e.target.result
      this.modalContent.error = null
      this.modalContent.imageState = true
    },
    checkInitialIcon (image) {
      if (typeof image === 'string') {
        return true
      }
      return false
    },
    /**
     * @public
     * @async
     * This method calls the API to fetch and store security groups if the isSecGroupDone variable is false. Then it updates the variable and attributes in securityGroups with the result and display name.
     * @returns {Promise<void>}
     */
    async loadSecurityGroups () {
      if (!this.isSecGroupDone) {
        this.isSecGroupDone = await this.fetchSecurityGroups()
        this.securityGroups[0].name = this.$CONFIG('WIZARD_PAGE_4').ACTION.SET_SECURITY_GROUPS
        this.securityGroups[0].done = this.isSecGroupDone
      }
    },
    // below is for formBuilder Usage
    chooseModelType () {
      this.$emit('selectModelType', this.modelTypeLabel)
      this.formLoading = true
    },
    cancelChoose () {
      this.$emit('cancelChoose', true)
    },
    /**
     * @public
     * This method updates the icon type as well as the window type to determine if the display should be an entity
     * icon or linked icon
     * @param type string type value 'entity' or 'link' type
     */
    updateIconAndWindowType (type) {
      this.window = type
      this.iconType = type
    },
    insertFieldAtCursorInLabelText (field) {
      /**
       * This function will insert the selected field
       * at the current position of the cursor in the input box of the label format
       */
      // Insert field at current cursor position
      const currCursorPosition = this.$refs.labelTextRef.selectionStart
      const insertedValue = field + this.$LOCAL('KEYS').CLOSE_BRACKET.key
      this.labelText = this.labelText.slice(0, currCursorPosition) + insertedValue + this.labelText.slice(currCursorPosition)

      // Set new cursor position after the inserted field
      const newCursorPosition = currCursorPosition + insertedValue.length
      this.$refs.labelTextRef.$el.focus()
      setTimeout(() => this.$refs.labelTextRef.setSelectionRange(newCursorPosition, newCursorPosition))

      // Hide dropdown
      this.labelTextDropdown = false
    },
    showLabelTextDropdown (event) {
      /**
       * This function will show the label text dropdown when there is a key event entered into the label format input box
       * When { is input, the dropdown will be displayed
       * When } is input, the dropdown will be hidden
       * When other characters is input, there will be no change to the display of the dropdown
       */
      if (event.keyCode === this.$LOCAL('KEYS').OPEN_BRACKET.keyCode) {
        // If user has input open bracket "{"
        this.labelTextDropdown = true
      } else if (event.keyCode === this.$LOCAL('KEYS').CLOSE_BRACKET.keyCode) {
        // If user has input close bracket "}"
        this.labelTextDropdown = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .heading {
    text-align: left;
  }

  .items {
    .item {
      display: flex;
      text-align: left;

      .status {
        align-self: center;
      }

      .name {
        flex-grow: 1;
        padding: 5px 10px;
        align-self: center;
      }
    }
  }

  .check {
    height: 16px;
    width: 12px;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='16' " +
    "viewBox='0 0 12 16'%3E%3Cpath fill-rule='evenodd' fill='%2328a745' d='M12 5l-8 8-4-4 1.5-1.5L4 10l6.5-6.5L12 5z'/%3E%3C/svg%3E");
  }

  .cross {
    height: 12px;
    width: 12px;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' " +
    "viewBox='0 0 24 24'%3E%3Cpath fill='red' d='M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 " +
    "8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z'/%3E%3C/svg%3E");
}

  .button-tab {
    width: 100%;
    border-radius: 0;
    background-color: #313947;
  }

  .tab-container {
    margin-bottom: 15px;
  }

  .icon-preview {
    height: 150px;
    width: 150px;
    align-self: center;
  }

  ::v-deep .dropdown-menu {
    max-height: 50vh;
    overflow-y: auto;
    height: auto;
    overflow-x: hidden;
  }
</style>
